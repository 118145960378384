import React, { useRef, useEffect } from 'react';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import { TssCacheProvider } from 'tss-react';
import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DefaultSeo, OrganizationJsonLd, SiteLinksSearchBoxJsonLd } from 'next-seo';
import { QueryClientProvider, Hydrate } from 'utils/react-query';
import find from 'lodash/find';
import get from 'lodash/get';
import CssBaseline from '@mui/material/CssBaseline';
import { DevTool } from '@hookform/devtools';
import { useForm } from 'react-hook-form';

import Notifier from 'components/Notifier';
import SnackbarProvider from 'components/uiLibrary/Snackbar';

import SEO from 'utils/seo';
import usePermissions from 'utils/permissions';
import NotificationPopupProvider from 'utils/hooks/useNotificationPopup';
import NotificationBannerProvider from 'utils/hooks/useNotificationBanner';
import DialogsProvider from 'utils/hooks/useDialogs';
import PageScrollProvider from 'utils/hooks/usePageScroll';
import { newQueryClient } from 'utils/queriesUtil';
import useAppContext from 'utils/hooks/useAppContext';
import {
  clearIntervalForPing,
  setIntervalForPing,
  useArtsConsolidatedJsonLd,
  useOpenSearchJsonLd,
} from 'utils/globals/app';
import { getCookie, removeCookie } from 'utils/cookie';
import useMarkerIO from 'utils/hooks/useMarkerIO';
import { SOCIAL_LOGIN } from 'constants/cookieConstants';

import { selectListData } from 'store/selectors';
import theme from 'src/theme';

import { initialState } from 'containers/App/reducer';
import { selectAppDomain } from 'containers/App/selectors';

const AppLayout = ({ tssCache, emotionCache, children }) => {
  const { isLoggedIn } = useAppContext();

  const onFocus = () => {
    if (isLoggedIn) {
      setIntervalForPing();
    }
  };

  const onBlur = () => {
    clearIntervalForPing();
  };

  const orgJsonLD = useArtsConsolidatedJsonLd();
  const openSearchJsonLD = useOpenSearchJsonLd();

  const { isTranslator } = usePermissions();
  const settings = useSelector(selectListData(selectAppDomain, initialState.settings.listName));
  const contextEditor = get(find(settings, { identifier: 'context_editor' }), 'value') === 'true';
  const { control } = useForm({
    mode: 'onChange',
  });

  useEffect(() => {
    if (isTranslator && document.dispatchEvent) {
      document.dispatchEvent(new Event('lokalise-update-elements'));
    }
  }, [contextEditor, isTranslator]);

  useEffect(() => {
    const isSocialLogin = getCookie(SOCIAL_LOGIN);

    if (isSocialLogin) {
      removeCookie(SOCIAL_LOGIN, {
        domain: process.env.COOKIE_DOMAIN,
      });
    }

    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);
    onFocus();
    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };

    // NOTE: To be executed once per load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useMarkerIO(isLoggedIn);

  return (
    <NotificationBannerProvider>
      <NotificationPopupProvider>
        <DialogsProvider>
          <PageScrollProvider>
            <Head>
              <meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,shrink-to-fit=no" />
            </Head>

            <DefaultSeo {...SEO} />
            {orgJsonLD && <OrganizationJsonLd {...orgJsonLD} />}
            {openSearchJsonLD && <SiteLinksSearchBoxJsonLd {...openSearchJsonLD} />}

            <TssCacheProvider value={tssCache}>
              <CacheProvider value={emotionCache} injectFirst>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <SnackbarProvider>
                    <Notifier />
                    {children}
                    <ReactQueryDevtools initialIsOpen={false} />
                    <DevTool control={control} />
                  </SnackbarProvider>
                </ThemeProvider>
              </CacheProvider>
            </TssCacheProvider>
          </PageScrollProvider>
        </DialogsProvider>
      </NotificationPopupProvider>
    </NotificationBannerProvider>
  );
};

const AppLayoutReactQueryWrapper = ({ tssCache, emotionCache, dehydratedState, children }) => {
  const queryClientRef = useRef();

  if (!queryClientRef.current) {
    queryClientRef.current = newQueryClient();
  }

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={dehydratedState}>
        <AppLayout tssCache={tssCache} emotionCache={emotionCache}>
          {children}
        </AppLayout>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default AppLayoutReactQueryWrapper;
